import getConfig from 'next/config'
import { BraintreeBranch, CurrencyCode, DesignationItem, RecurringPaymentBasket, RecurringPaymentResult, SinglePaymentBasket, SinglePaymentResult } from '../models/braintree/DonationModels'
import IPaymentFormInfo from '../models/braintree/IPaymentFormInfo'
import BraintreeConfig from './braintree/shared/braintree-config'

declare global {
    interface Window {
        gtag: any;
        fbq: any;
    }
}

const analytics = getConfig().publicRuntimeConfig.analytics

// https://developers.google.com/analytics/devguides/collection/gtagjs/pages
 const logPageView = (url) => {
  window.gtag('config', analytics.googleAnalyticsId, {
    page_path: url,
  })
}

const logAddItemToBasket = (item: DesignationItem,currency:CurrencyCode)=> {

  const track = {id: item.id, value: item.amount,currency}
  window.gtag('event','add_to_cart',track)
  if(window.fbq) window.fbq('track', 'AddToCart',track);
}

const logInitiateCheckout = (paymentInfo: IPaymentFormInfo)=> {

  const track = {
    currency: paymentInfo.currencyCode,
    value: BraintreeConfig.getTotalAmountNumber(paymentInfo.netAmount,paymentInfo.coverTransactionCosts),
    payment_type: paymentInfo.isRecurring ? "recurring": "single"
  }

  window.gtag('event','begin_checkout',track)
  if(window.fbq) window.fbq('track', 'InitiateCheckout',track);
}

const logAddPaymentInfo = (paymentInfo: IPaymentFormInfo)=> {

  //add whether we cover the costs
  const track = {
    currency: paymentInfo.currencyCode,
    value: BraintreeConfig.getTotalAmountNumber(paymentInfo.netAmount,paymentInfo.coverTransactionCosts),
    payment_type: paymentInfo.isRecurring ? "recurring": "single"
  }

  window.gtag('event','add_payment_info',track)
  if(window.fbq) window.fbq('track', 'AddPaymentInfo',track);
}

const logSingleDonation = (basket: SinglePaymentBasket, transactionId: String)=>{

  var trackingObject ={
    "transaction_id": transactionId,
    "payment_type": "single",
    "payment_method": basket.paymentMethod,
    "value": +basket.totalAmount,
    "currency": basket.currency_code,
    "items": basket.items.map(x => {
      return {
        "id": x.id,
        "name": x.name,
        "quantity": x.amount,
        "price": "1.0"
      }
    })
  }

  
  window.gtag('event', 'purchase',  trackingObject);
  if(window.fbq) {
    window.fbq('track', 'Purchase', trackingObject);
  }

}

const logRecurringDonation = (basket: RecurringPaymentBasket, subscriptionId: String)=>{

  const trackingObject = {
    "transaction_id": subscriptionId,
    "value": +basket.totalAmount,
    "payment_type": "recurring",
    "currency": basket.currency_code,
    "payment_method": basket.paymentMethod,
    "cover_transaction_costs": basket.coverTransactionCosts
  }

  window.gtag('event', 'purchase',  trackingObject);
  if(window.fbq) window.fbq('track', 'Purchase', trackingObject);

  console.log("LX ogged recurring event to e-commerce")
}

// https://developers.google.com/analytics/devguides/collection/gtagjs/events
 const logEvent = (category:string,action:string, label:string, value:number ) => {
  
  //this needs to become more like
  window.gtag('event', action, {
    event_category: category,
    event_label: label,
    value: value,
  })
}

const analyticsService = {
    logEvent,
    logPageView,
    logAddItemToBasket,
    logInitiateCheckout,
    logAddPaymentInfo,
    logSingleDonation,
    logRecurringDonation
}

export default analyticsService
