import { RichText } from 'prismic-reactjs'
import {
  CurrencyCode,
  DesignationItem,
} from '../models/braintree/DonationModels'
import analyticsService from './analytics-service'

const addToBasket = (
  basketItems: DesignationItem[],
  selectedProject: { id: string; name: string },
  amount: string | number,
  currencyCode: CurrencyCode,
  basketContext: any,
  setAmount: (amount: number) => void,
  note: string,
  setNote: (note: string) => void
): DesignationItem[] => {
  let tempCopyOfBasket = [...basketItems]
  let projectIndex = tempCopyOfBasket.findIndex(
    (item) => item.id == selectedProject.id
  )
  if (projectIndex != -1) {
    tempCopyOfBasket[projectIndex]['amount'] =
      tempCopyOfBasket[projectIndex]['amount'] + +amount
  } else {
    // it is a new item. Add it in to the basket
    const newItem: DesignationItem = {
      amount: +amount,
      name: selectedProject.name,
      id: selectedProject.id,
      note: note,
    }
    tempCopyOfBasket = [...basketItems, newItem]
    analyticsService.logAddItemToBasket(newItem, currencyCode)
  }
  localStorage.setItem('Basket', JSON.stringify(tempCopyOfBasket))
  basketContext.basket.setBasket(tempCopyOfBasket)
  setAmount(undefined)
  setNote('')
  return tempCopyOfBasket
}

const getAvailableProjects = (layout: any): { id: string; name: string }[] => {
  if (layout && layout.donation_dedication_options) {
    return layout.donation_dedication_options.map((x) => {
      return { id: x.designation_id, name: RichText.asText(x.dedication_fund) }
    })
  }

  return []
}

const donationService = {
  addToBasket,
  getAvailableProjects,
}

export default donationService
