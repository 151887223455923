import React, { useEffect, useState } from 'react'
import PropTypes from 'prop-types'
import { RichText } from 'prismic-reactjs'
import { withStyles } from '@material-ui/core/styles'
import Typography from '@material-ui/core/Typography'
import { Button, Grid, InputAdornment, Select } from '@material-ui/core'
import ButtonGroup from '@material-ui/core/ButtonGroup'
import TextField from '@material-ui/core/TextField'
import MenuItem from '@material-ui/core/MenuItem'
import style from './style'
import { useLayout } from '../../utils/layout-context'
import { structuredTextPropTypes, imagePropTypes } from '../../utils/prop-types'
import {
  useVFIStoreState,
  useVFIStoreActions,
} from '../../store/vfi-easy-peasy-store'
import { useBasket } from '../../utils/basket-context'
import { useRouter } from 'next/router'
import { DesignationItem } from '../../models/braintree/DonationModels'
import donationService from '../../services/donation-service'
import { allCurrencies } from '../../services/currency-service'
import { CurrencyCode } from '../../models/braintree/DonationModels'
import { motion } from 'framer-motion'
import { vTextBlurWhite, vTextFade } from '../../utils/animations'
import { linkResolver } from '../../prismicio'

const DonateNow = ({ slice, classes }) => {
  const { primary } = slice
  const layout = useLayout().layout
  const basketContext = useBasket()
  const router = useRouter()
  const [basketItems, setBasketItems] = useState<DesignationItem[]>([])
  const [amount, setAmount] = useState<number>(null)
  const [note, setNote] = useState<string>('')
  const [selectedProject, setSelectedProject] = useState(null)
  const [selectedProjectIndex, setSelectedProjectIndex] = useState<number>(0)

  const styles: any = {}

  const state = useVFIStoreState((state) => state)
  const actions = useVFIStoreActions((actions) => actions)
  const availableProjects = donationService.getAvailableProjects(layout)

  if (primary.backgroundImage) {
    styles.backgroundImage = `url(${primary.backgroundImage.url})`
  }

  //#region EFFECTS
  useEffect(() => {
    if (selectedProjectIndex > -1) {
      setSelectedProject(availableProjects[selectedProjectIndex])
    } else {
      setSelectedProject(null)
    }
  }, [selectedProjectIndex])

  useEffect(() => {
    let basketTotal
    if (localStorage.getItem('Basket')) {
      setBasketItems(JSON.parse(localStorage.getItem('Basket')))
      basketTotal = JSON.parse(localStorage.getItem('Basket'))
    }
  }, [])

  const addToBasket = () => {
    donationService.addToBasket(
      basketItems,
      selectedProject,
      amount,
      state.currency.code,
      basketContext,
      setAmount,
      note,
      setNote
    )

    setSelectedProjectIndex(0)
    setSelectedProject(null)
    router.push(linkResolver(layout.give_button_link))
  }

  const viewport = {
    once: true,
    margin: '-6%',
  }

  return (
    <div className={classes.donate}>
      {layout && (
        <div className="bgImg" style={styles}>
          <div className={classes.donateCardBg}>
            <div className={classes.donateCard}>
              <motion.div
                className="donateLeft"
                variants={vTextFade}
                initial={'offscreen'}
                whileInView={'onscreen'}
                viewport={viewport}
                transition={{
                  type: 'tween',
                  delay: 0.4,
                  duration: 0.8,
                }}
              >
                <Typography variant="h5" className="title">
                  {RichText.asText(layout.homepage_donation_card_title)}
                </Typography>
                <div className="label">
                  {RichText.asText(layout.select_amount_text)}
                </div>
                <ButtonGroup color="primary" className="amtGroup">
                  {layout.amounts.map((amount, i) => (
                    <Button
                      style={{
                        backgroundColor: amount.amount == amount && '#4a92cf',
                      }}
                      onClick={() => setAmount(amount.amount)}
                      key={i}
                    >
                      {state.currency.symbol} {amount.amount}
                    </Button>
                  ))}
                </ButtonGroup>
                <form
                  onSubmit={(e) => {
                    e.preventDefault()
                    addToBasket()
                  }}
                >
                  <Grid container spacing={2} alignItems="center">
                    <Grid item xs={12} sm={5} md={4}>
                      <Select
                        displayEmpty
                        fullWidth
                        id="currency"
                        variant="outlined"
                        margin="dense"
                        required
                        value={state.currency.code}
                        onChange={(e) => {
                          actions.setCurrency(e.target.value as CurrencyCode)
                        }}
                      >
                        {allCurrencies.map((currency, i) => {
                          return (
                            <MenuItem
                              className={classes.selectMenuItem}
                              key={i}
                              value={currency.code}
                            >
                              {currency.code} {currency.symbol}
                            </MenuItem>
                          )
                        })}
                      </Select>
                    </Grid>
                    <Grid item xs={12} sm={7} md={8}>
                      <TextField
                        placeholder={RichText.asText(
                          layout.other_amount_placeholder
                        )}
                        fullWidth
                        inputProps={{
                          inputMode: 'decimal',
                        }}
                        required
                        type="number"
                        variant="outlined"
                        margin="dense"
                        value={amount ? Number(amount) : ''}
                        onChange={(e) => {
                          setAmount(+e.target.value || null)
                        }}
                      />
                    </Grid>
                  </Grid>
                  <div className="label">
                    {RichText.asText(layout.dedicate_donation_placeholder)}
                  </div>
                  <Select
                    displayEmpty
                    id="dedicateTo"
                    variant="outlined"
                    fullWidth
                    required
                    margin="dense"
                    value={selectedProjectIndex}
                    onChange={(e) => {
                      setSelectedProjectIndex(+e.target.value)
                    }}
                    className={classes.custInput}
                  >
                    {availableProjects.map((project, i) => {
                      return (
                        <MenuItem
                          className={classes.selectMenuItem}
                          key={i}
                          value={i}
                        >
                          {project.name}
                        </MenuItem>
                      )
                    })}
                  </Select>
                  <Button
                    variant="contained"
                    color="primary"
                    type="submit"
                    className="donateBtn"
                    disabled={amount <= 0}
                  >
                    {RichText.asText(layout.donate_now_button_text)}
                  </Button>
                </form>
              </motion.div>

              <div className="donateRight">
                <motion.div
                  variants={vTextBlurWhite}
                  initial={'offscreen'}
                  whileInView={'onscreen'}
                  viewport={viewport}
                >
                  {RichText.render(primary.rightText)}
                </motion.div>
              </div>
            </div>
          </div>
        </div>
      )}
    </div>
  )
}

DonateNow.propTypes = {
  slice: PropTypes.shape({
    primary: PropTypes.shape({
      background_image: imagePropTypes,
      right_text: structuredTextPropTypes,
    }).isRequired,
    items: PropTypes.array.isRequired,
  }).isRequired,
  classes: PropTypes.objectOf(PropTypes.any).isRequired,
}

export default withStyles(style)(DonateNow)
