import { createStyles, Theme } from "@material-ui/core";

const styles = (theme: Theme) => createStyles({
  amountInput: {
    marginLeft: 15
  },
  donate: {
    backgroundColor: "#4A92CF",
    display: "flex",
    justifyContent: "center",
    "& .bgImg": {
      width: "100%",
      height: "100%",
      backgroundPosition: "center",
      backgroundSize: "cover",
      display: "flex",
      justifyContent: "center",
    },
  },
  donateCardBg: {
    backgroundImage: "url(/homevideoBg.png)",
    backgroundPosition: "0%",
    backgroundSize: "100.4% 100%",
    backgroundRepeat: "no-repeat",
    display: "flex",
    justifyContent: "center",
    width: "100%",
    height: "100%",
    paddingTop: theme.spacing(30),
    paddingBottom: theme.spacing(20),
    [theme.breakpoints.down("sm")]: {
      paddingRight: theme.spacing(10),
      paddingLeft: theme.spacing(10),
    },
    [theme.breakpoints.down("xs")]: {
      paddingTop: theme.spacing(8),
      paddingBottom: theme.spacing(8),
      paddingRight: theme.spacing(2),
      paddingLeft: theme.spacing(2),
      backgroundSize: "cover",
      backgroundPosition: "center",
    },
  },
  donateCard: {
    maxWidth: 890,
    borderRadius: 12,
    zIndex: 9,
    boxShadow: "0 20px 30px 0 rgba(147, 158, 193, 0.2)",
    display: "flex",
    [theme.breakpoints.down("sm")]: {
      display: "block",
      height: "auto",
    },
    "& .donateLeft": {
      flex: 1,
      backgroundColor: "#ffffff",
      display: "flex",
      justifyContent: "center",
      flexDirection: "column",
      padding: theme.spacing(5),
      [theme.breakpoints.down("sm")]: {
        padding: theme.spacing(2),
      },
      "& .amtGroup": {
        marginBottom: theme.spacing(3),
        "& button:first-child": {
          borderTopRightRadius: theme.direction === "ltr" ? 0 : 6,
          borderBottomRightRadius: theme.direction === "ltr" ? 0 : 6,
          borderTopLeftRadius: theme.direction === "ltr" ? 6 : 0,
          borderBottomLeftRadius: theme.direction === "ltr" ? 6 : 0,
        },
        "& button:last-child": {
          borderTopLeftRadius: theme.direction === "ltr" ? 0 : 6,
          borderBottomLeftRadius: theme.direction === "ltr" ? 0 : 6,
          borderTopRightRadius: theme.direction === "ltr" ? 6 : 0,
          borderBottomRightRadius: theme.direction === "ltr" ? 6 : 0,
        }
      },
      "& .MuiButton-outlinedPrimary": {
        color: "#4c4c78 ",
        border: "solid 1px #4c4c78",
        flex: 1,
        fontWeight: 700,
        height: "fit-content",
        padding: theme.spacing(1.3, 0),
        "&:hover": {
          backgroundColor: '#4a92cf',
        },
      },
      "& .title": {
        textTransform: 'uppercase',
        fontFamily: "Raleway,Assistant",
        fontWeight: 900,
        color: "#4c4c78",
        textAlign: "center",
      },
      "& .label": {
        fontFamily: "Raleway,Assistant",
        fontSize: 14,
        fontWeight: 700,
        color: "#4c4c78",
        marginTop: theme.spacing(4),
        marginBottom: theme.spacing(2.5),
        [theme.breakpoints.down("sm")]: {
          marginTop: theme.spacing(3),
          marginBottom: theme.spacing(2),
        },
        [theme.breakpoints.down("xs")]: {
          marginTop: theme.spacing(2),
          marginBottom: theme.spacing(1),
        },
      },
      "& .MuiOutlinedInput-root:hover .MuiOutlinedInput-notchedOutline": {
        borderColor: '#8bc249',
      },
      "& fieldset": {
        border: '2px solid #f3f3f3',
        "&:hover": {
          border: '2px solid #8bc249',
        }
      },
      "& .donateBtn": {
        margin: "0 auto",
        marginTop: theme.spacing(4),
        display: "flex",
        [theme.breakpoints.down("sm")]: {
          padding: theme.spacing(3),
          marginTop: theme.spacing(3),
        },
        [theme.breakpoints.down("xs")]: {
          padding: theme.spacing(2),
          marginTop: theme.spacing(2),
        },
      },
    },
    "& .donateRight": {
      display: "flex",
      flex: 1,
      alignItems: "center",
      backgroundColor: "#242440 ",
      padding: theme.spacing(0, 5, 0, 7.5),
      [theme.breakpoints.down("sm")]: {
        padding: theme.spacing(2),
      },
      "& p": {
        fontFamily: "Raleway,Assistant",
        fontSize: 21,
        fontWeight: 700,
        lineHeight: 1.62,
        textShadow: "inherit",
        color: "inherit",
        [theme.breakpoints.down("sm")]: {
          margin: theme.spacing(0),
        },
        "& .underline": {
          borderBottom: "2px solid #4a92cf",
        },
      },
    },
  },
  selectMenuItem: {
    direction: theme.direction === "ltr" ? 'ltr' : 'rtl',
  }
});

export default styles;
